import React from 'react'
import { Link } from 'gatsby'
import { Box } from 'grommet'

import Heading from '../components/Heading'
import ContentContainer from '../components/ContentContainer'
import Button from '../components/Button'
import Layout from '../components/layout'
import SEO from '../components/seo'
import VisualPostcards from '../components/VisualPostcards'
import VisualLetters from '../components/VisualLetters'

const IndexPage = () => (
  <Layout>
    <SEO title="Modern Mail" keywords={[`gatsby`, `application`, `react`]} />
    <Box pad={{ vertical: 'small' }} flex="grow" justify="center">
      <Heading level={2} textAlign="center" alignSelf="center">
        What would you like to mail?
      </Heading>

      <Box fill direction="row-responsive" gap="medium">
        <Box fill="vertical" flex pad="small">
          <ContentContainer side="left">
            <VisualPostcards />
            <Box alignSelf="center">
              <Link to="/postcards">
                <Button primary>Postcards</Button>
              </Link>
            </Box>
          </ContentContainer>
        </Box>
        <Box flex fill="vertical" pad="small">
          <ContentContainer>
            <VisualLetters />
            <Box align="center">
              <Link to="/letters">
                <Button primary>Letters</Button>
              </Link>
            </Box>
          </ContentContainer>
        </Box>
      </Box>
    </Box>
  </Layout>
)

export default IndexPage
